<!--
 * @Description: 仓储库存
 * @Author: 琢磨先生
 * @Date: 2022-05-18 14:34:07
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-05-18 14:34:07
-->
<template>
    <div>
开发中...
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>